import React from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Paper,
  FormControl,
  Select,
  MenuItem,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Alert,
  Button,
  IconButton,
  styled,
} from "@mui/material";
import { Clock, Radio, User, PlayCircle, Music } from "lucide-react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { usePlayerLogic, formatDuration } from "../lib/playerpage";

// Styled components
const StyledAudioPlayer = styled(AudioPlayer)`
  &.rhap_container {
    background-color: ${(props) => props.theme.palette.background.paper};
    box-shadow: none;
  }
  .rhap_time {
    color: ${(props) => props.theme.palette.text.secondary};
  }
  .rhap_progress-filled {
    background-color: ${(props) => props.theme.palette.primary.main};
  }
  .rhap_download-progress {
    background-color: ${(props) => props.theme.palette.action.hover};
  }
`;

const StyledTimeSlot = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  height: "100%",
  display: "flex",
  flexDirection: "column",
  position: "relative",
  "&:hover": {
    boxShadow: theme.shadows[4],
  },
}));

const StyledEpisodeCount = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: theme.palette.text.secondary,
  fontSize: theme.typography.caption.fontSize,
  "& > svg": {
    marginRight: theme.spacing(0.5),
    width: 16,
    height: 16,
  },
}));

// Station Selector Component
const StationSelector = ({
  playlists,
  selectedPlaylist,
  onSelect,
  onSubscribe,
  onUnsubscribe,
  disabled,
}) => {
  return (
    <Box>
      <FormControl fullWidth size="small" disabled={disabled}>
        <Select
          value={selectedPlaylist?.id || ""}
          onChange={(e) => {
            const playlist = playlists.find((p) => p.id === e.target.value);
            onSelect(playlist);
          }}
          displayEmpty
          sx={{ mb: 1 }}
        >
          <MenuItem value="">
            <em>Select Station</em>
          </MenuItem>
          {playlists.map((playlist) => (
            <MenuItem key={playlist.id} value={playlist.id}>
              <Box>
                <Typography>{playlist.title}</Typography>
                <Box display="flex" alignItems="center" gap={2}>
                  <StyledEpisodeCount>
                    <Music />
                    {playlist.episode_count || 0} episodes
                  </StyledEpisodeCount>
                  {playlist.creator_name && (
                    <Typography variant="caption" color="text.secondary">
                      {playlist.creator_name}
                    </Typography>
                  )}
                </Box>
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedPlaylist && onSubscribe && onUnsubscribe && (
        <Box
          mt={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="caption" color="text.secondary">
            {selectedPlaylist.last_7_days_plays?.toLocaleString()} plays this
            week
          </Typography>
          <Button
            size="small"
            variant="outlined"
            onClick={() =>
              selectedPlaylist.isSubscribed
                ? onUnsubscribe(selectedPlaylist.id)
                : onSubscribe(selectedPlaylist.id)
            }
            disabled={disabled}
          >
            {selectedPlaylist.isSubscribed ? "Unsubscribe" : "Subscribe"}
          </Button>
        </Box>
      )}
    </Box>
  );
};

// Daily Schedule Component
const DailySchedule = ({
  timeSlots,
  subscribedPlaylists,
  schedule,
  updateSchedule,
  getEpisodesForPlaylist,
  currentTimeSlot,
  isLoading,
}) => {
  return (
    <Paper elevation={2} sx={{ p: 3 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h6">Daily Schedule</Typography>
        <Box display="flex" alignItems="center">
          <Clock size={16} sx={{ mr: 1 }} />
          <Typography variant="caption" color="text.secondary">
            Current: {currentTimeSlot}
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={2}>
        {timeSlots.map((timeSlot) => {
          const scheduledPlaylist = schedule[timeSlot];
          const episodes = scheduledPlaylist
            ? getEpisodesForPlaylist(scheduledPlaylist.id)
            : null;
          const isCurrentSlot = timeSlot === currentTimeSlot;

          return (
            <Grid item xs={12} key={timeSlot}>
              <StyledTimeSlot
                sx={
                  isCurrentSlot
                    ? {
                        borderColor: "primary.main",
                        borderWidth: 1,
                        borderStyle: "solid",
                        backgroundColor: (theme) =>
                          theme.palette.primary.light + "10",
                      }
                    : {}
                }
              >
                <Box
                  display="flex"
                  alignItems="center"
                  mb={1}
                  justifyContent="space-between"
                >
                  <Box display="flex" alignItems="center">
                    <Clock size={16} />
                    <Typography
                      variant="body2"
                      ml={1}
                      color={isCurrentSlot ? "primary" : "text.secondary"}
                    >
                      {timeSlot}
                    </Typography>
                  </Box>
                  {scheduledPlaylist?.last_7_days_plays && (
                    <Typography variant="caption" color="text.secondary">
                      {scheduledPlaylist.last_7_days_plays.toLocaleString()}{" "}
                      weekly plays
                    </Typography>
                  )}
                </Box>
                <StationSelector
                  playlists={subscribedPlaylists}
                  selectedPlaylist={scheduledPlaylist}
                  onSelect={(playlist) => updateSchedule(timeSlot, playlist)}
                  disabled={isLoading}
                />
                {scheduledPlaylist && episodes?.[0] && (
                  <Box mt={2} p={2} bgcolor="action.hover" borderRadius={1}>
                    <Typography variant="subtitle2" gutterBottom>
                      {episodes[0].title}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="caption" color="text.secondary">
                        {formatDuration(episodes[0].duration)}
                      </Typography>
                      {episodes[0].show_name && (
                        <Typography variant="caption" color="text.secondary">
                          {episodes[0].show_name}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                )}
              </StyledTimeSlot>
            </Grid>
          );
        })}
      </Grid>
    </Paper>
  );
};

// Now Playing Component
const NowPlaying = ({
  currentStation,
  currentEpisode,
  isPlaying,
  handlePlay,
  handlePause,
}) => (
  <Box>
    <Box display="flex" gap={2} mb={3}>
      {currentStation?.image_url ? (
        <CardMedia
          component="img"
          sx={{ width: 140, height: 140, borderRadius: 1 }}
          image={currentStation.image_url}
          alt={currentStation.title}
        />
      ) : (
        <Box
          sx={{
            width: 140,
            height: 140,
            borderRadius: 1,
            bgcolor: "action.selected",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Radio size={48} />
        </Box>
      )}
      <Box flex={1}>
        <Typography variant="h6">{currentStation?.title}</Typography>
        <Typography variant="body2" color="text.secondary">
          {currentStation?.description}
        </Typography>
        <Box display="flex" gap={2} mt={1}>
          {currentStation?.creator_name && (
            <Box display="flex" alignItems="center">
              <User size={16} />
              <Typography variant="body2" ml={1} color="text.secondary">
                {currentStation.creator_name}
              </Typography>
            </Box>
          )}
          <StyledEpisodeCount>
            <Music />
            {currentStation?.episode_count || 0} episodes
          </StyledEpisodeCount>
          {currentStation?.last_7_days_plays > 0 && (
            <Box display="flex" alignItems="center">
              <PlayCircle size={16} />
              <Typography variant="body2" ml={1} color="text.secondary">
                {currentStation.last_7_days_plays.toLocaleString()} weekly plays
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
    {currentEpisode?.audio_url && (
      <StyledAudioPlayer
        autoPlay={isPlaying}
        src={currentEpisode.audio_url}
        onPlay={() => {
          if (!isPlaying) {
            handlePlay(currentStation);
          }
        }}
        onPause={handlePause}
        showSkipControls={false}
        showJumpControls={true}
        header={
          <Box>
            <Typography variant="subtitle1">{currentEpisode.title}</Typography>
            {currentEpisode.show_name && (
              <Typography variant="caption" color="text.secondary">
                {currentEpisode.show_name}
              </Typography>
            )}
          </Box>
        }
        customControlsSection={["MAIN_CONTROLS", "VOLUME_CONTROLS"]}
        autoPlayAfterSrcChange={true}
        progressUpdateInterval={100}
      />
    )}
  </Box>
);
// Main Player Page Component
export const PlayerPage = () => {
  const {
    currentStation,
    currentEpisode,
    isPlaying,
    isLoading,
    error,
    subscribedPlaylists,
    schedule,
    timeSlots,
    handlePlay,
    handlePause,
    handleSubscribe,
    handleUnsubscribe,
    updateSchedule,
    getEpisodesForPlaylist,
    getCurrentTimeSlot,
  } = usePlayerLogic();

  return (
    <Container maxWidth="xl">
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Grid container spacing={3}>
        {/* Player Section */}
        <Grid item xs={12} md={8}>
          <Card elevation={2}>
            <CardContent>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Typography variant="h5">Now Playing</Typography>
                {isPlaying && getCurrentTimeSlot() && (
                  <Typography variant="caption" color="text.secondary">
                    Scheduled for {getCurrentTimeSlot()}
                  </Typography>
                )}
              </Box>
              {isLoading ? (
                <Box display="flex" justifyContent="center" py={6}>
                  <CircularProgress />
                </Box>
              ) : currentStation ? (
                <NowPlaying
                  currentStation={currentStation}
                  currentEpisode={currentEpisode}
                  isPlaying={isPlaying}
                  handlePlay={handlePlay}
                  handlePause={handlePause}
                />
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  py={6}
                >
                  <Radio size={48} />
                  <Typography variant="h6" mt={2}>
                    Select a station to start playing
                  </Typography>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Schedule Section */}
        <Grid item xs={12} md={4}>
          <DailySchedule
            timeSlots={timeSlots}
            subscribedPlaylists={subscribedPlaylists}
            schedule={schedule}
            updateSchedule={updateSchedule}
            getEpisodesForPlaylist={getEpisodesForPlaylist}
            currentTimeSlot={getCurrentTimeSlot()}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default PlayerPage;
