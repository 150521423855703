import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Container,
  useTheme,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Stack,
  Paper,
  Chip,
} from "@mui/material";
import {
  Headphones as HeadphonesIcon,
  Schedule as ScheduleIcon,
  RadioOutlined as RadioIcon,
  PlaylistAdd as PlaylistIcon,
} from "@mui/icons-material";
import { supabase } from "../lib/supabase";

// Hero component stays exactly the same
const Hero = React.memo(() => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: "relative",
        textAlign: "center",
        py: { xs: 8, md: 12 },
        px: 2,
        background: (theme) =>
          `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
        color: "white",
        overflow: "hidden",
      }}
    >
      <Container maxWidth="md">
        <Typography
          variant="h2"
          component="h1"
          sx={{
            fontWeight: 700,
            fontSize: { xs: "2rem", sm: "3rem", md: "3.75rem" },
            mb: 2,
            position: "relative",
            zIndex: 1,
          }}
        >
          Discover Your Perfect Podcast Moment
        </Typography>
        <Typography
          variant="h5"
          sx={{
            mb: 4,
            opacity: 0.9,
            position: "relative",
            zIndex: 1,
          }}
        >
          Hand-picked stations for every part of your day
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={() => navigate("/auth")}
          startIcon={<HeadphonesIcon />}
          sx={{
            px: 4,
            py: 1.5,
            borderRadius: 2,
            textTransform: "none",
            fontSize: "1.1rem",
            position: "relative",
            zIndex: 1,
            "&:hover": {
              transform: "translateY(-2px)",
            },
            transition: "transform 0.2s",
          }}
        >
          Get Started
        </Button>
      </Container>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          opacity: 0.1,
          background: 'url("/pattern.svg")',
          backgroundSize: "cover",
        }}
      />
    </Box>
  );
});

const FeatureSection = () => {
  return (
    <Box sx={{ py: 8, bgcolor: "background.paper" }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Paper
              elevation={0}
              sx={{ p: 3, height: "100%", textAlign: "center" }}
            >
              <RadioIcon sx={{ fontSize: 48, color: "primary.main", mb: 2 }} />
              <Typography variant="h6" gutterBottom>
                Create Your Own Podcast Station
              </Typography>
              <Typography color="text.secondary">
                Curate your perfect podcast mix from any show and share it with
                the world
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper
              elevation={0}
              sx={{ p: 3, height: "100%", textAlign: "center" }}
            >
              <ScheduleIcon
                sx={{ fontSize: 48, color: "primary.main", mb: 2 }}
              />
              <Typography variant="h6" gutterBottom>
                Schedule Content
              </Typography>
              <Typography color="text.secondary">
                Program your podcast stations to play at specific times
                throughout your day
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper
              elevation={0}
              sx={{ p: 3, height: "100%", textAlign: "center" }}
            >
              <PlaylistIcon
                sx={{ fontSize: 48, color: "primary.main", mb: 2 }}
              />
              <Typography variant="h6" gutterBottom>
                Discover Stations
              </Typography>
              <Typography color="text.secondary">
                Follow other curatrs and discover perfectly timed content for
                any moment
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

const PlaylistCard = ({ playlist }) => {
  const navigate = useNavigate();

  return (
    <Card
      onClick={() => navigate(`/playlists/${playlist.id}`)}
      sx={{
        height: "100%",
        cursor: "pointer",
        transition: "transform 0.2s",
        "&:hover": {
          transform: "translateY(-4px)",
          boxShadow: 6,
        },
      }}
    >
      <CardMedia
        component="img"
        height="200"
        image={playlist.image_url}
        alt={playlist.title}
      />
      <CardContent>
        <Typography gutterBottom variant="h6">
          {playlist.title}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          by {playlist.creator}
        </Typography>
        <Stack direction="row" spacing={1}>
          <Chip size="small" label={`${playlist.episode_count} episodes`} />
          <Chip size="small" label={playlist.time_slot} />
        </Stack>
      </CardContent>
    </Card>
  );
};

const FeaturedPlaylists = () => {
  const [playlists, setPlaylists] = useState([]);

  useEffect(() => {
    const fetchPlaylists = async () => {
      try {
        const { data } = await supabase.from("playlists").select("*").limit(3);
        setPlaylists(data || []);
      } catch (error) {
        console.error("Error fetching playlists:", error);
      }
    };

    fetchPlaylists();
  }, []);

  return (
    <Box sx={{ py: 8 }}>
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom>
          Featured Stations
        </Typography>
        <Grid container spacing={3}>
          {playlists.map((playlist) => (
            <Grid item key={playlist.id} xs={12} sm={6} md={4}>
              <PlaylistCard playlist={playlist} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

const Home = () => {
  return (
    <Box>
      <Hero />
      <FeatureSection />
      <FeaturedPlaylists />
    </Box>
  );
};

export default Home;
