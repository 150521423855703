// discoverLib.js
import { supabase } from "../lib/supabase";

export const fetchPlaylists = async () => {
  const { data, error } = await supabase
    .from("playlists")
    .select(
      `
      *,
      creator:profiles!playlists_creator_id_fkey(full_name),
      episodes(count),
      stats:playlist_stats(last_7_days_plays)
    `
    )
    .order("created_at", { ascending: false });

  if (error) throw error;
  if (!data) return [];

  return data.map((playlist) => ({
    ...playlist,
    creator_name: playlist.creator?.full_name || "Unknown Creator",
    episode_count: playlist.episodes[0]?.count || 0,
    last_7_days_plays: playlist.stats?.last_7_days_plays || 0,
  }));
};

export const fetchPlaylistDetails = async (playlistId) => {
  const { data: playlist, error } = await supabase
    .from("playlists")
    .select(
      `
      *,
      creator:profiles!playlists_creator_id_fkey(full_name),
      episodes(
        *,
        stats:playlist_stats(total_plays)
      ),
      stats:playlist_stats(total_plays, total_subscribers)
    `
    )
    .eq("id", playlistId)
    .single();

  if (error) throw error;
  if (!playlist) return null;

  return {
    ...playlist,
    creator_name: playlist.creator?.full_name || "Unknown Creator",
    total_plays: playlist.stats?.total_plays || 0,
    total_subscribers: playlist.stats?.total_subscribers || 0,
  };
};

export const fetchUserSubscriptions = async (userId) => {
  const { data, error } = await supabase
    .from("subscriptions")
    .select(
      `
      *,
      playlist:playlists(
        *,
        creator:profiles!playlists_creator_id_fkey(full_name),
        episodes(count),
        stats:playlist_stats(last_7_days_plays)  
      )
    `
    )
    .eq("user_id", userId);

  if (error) throw error;
  if (!data) return [];

  return data.map((sub) => ({
    ...sub,
    ...sub.playlist,
    creator_name: sub.playlist.creator?.full_name || "Unknown Creator",
    episode_count: sub.playlist.episodes[0]?.count || 0,
    last_7_days_plays: sub.playlist.stats?.last_7_days_plays || 0,
  }));
};

export const subscribeToPlaylist = async (userId, playlistId) => {
  const { data, error } = await supabase
    .from("subscriptions")
    .insert({ user_id: userId, playlist_id: playlistId })
    .select()
    .single();

  if (error) throw error;
  return data;
};

export const unsubscribeFromPlaylist = async (userId, playlistId) => {
  const { error } = await supabase
    .from("subscriptions")
    .delete()
    .eq("user_id", userId)
    .eq("playlist_id", playlistId);

  if (error) throw error;
};
