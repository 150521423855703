const APPLE_API_BASE = "https://itunes.apple.com";

// Error handling helper
const handleAppleError = (error) => {
  console.error("Apple API error:", error);
  return {
    error: error.message || "Failed to fetch from Apple Podcasts",
    data: null,
  };
};

export const appleApi = {
  // Search podcasts
  searchPodcasts: async (query, limit = 20) => {
    try {
      const response = await fetch(
        `${APPLE_API_BASE}/search?term=${encodeURIComponent(
          query
        )}&entity=podcast&limit=${limit}`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return {
        data: data.results || [],
        error: null,
      };
    } catch (error) {
      return handleAppleError(error);
    }
  },

  // Get podcast episodes
  getPodcastEpisodes: async (podcastId) => {
    try {
      const response = await fetch(
        `${APPLE_API_BASE}/lookup?id=${podcastId}&entity=podcastEpisode&limit=100`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const episodes =
        data.results?.filter((item) => item.kind === "podcast-episode") || [];

      return {
        data: episodes,
        error: null,
      };
    } catch (error) {
      return handleAppleError(error);
    }
  },

  // Format episode for database storage
  formatEpisodeForStorage: (episode) => ({
    apple_id: episode.trackId.toString(),
    title: episode.trackName,
    show_name: episode.collectionName,
    description: episode.description || "",
    duration: episode.trackTimeMillis,
    audio_url: episode.episodeUrl,
    release_date: episode.releaseDate,
    artwork_url: episode.artworkUrl600 || null,
  }),
};

// Utility functions
export const podcastUtils = {
  // Format duration for display
  formatDuration: (milliseconds) => {
    const minutes = Math.floor(milliseconds / 60000);
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (hours > 0) {
      return `${hours}h ${remainingMinutes}m`;
    }
    return `${remainingMinutes}m`;
  },

  // Sort episodes by date
  sortEpisodesByDate: (episodes, ascending = true) => {
    return [...episodes].sort((a, b) => {
      const dateA = new Date(a.releaseDate);
      const dateB = new Date(b.releaseDate);
      return ascending ? dateA - dateB : dateB - dateA;
    });
  },

  // Filter episodes by date range
  filterEpisodesByDateRange: (episodes, startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    return episodes.filter((episode) => {
      const episodeDate = new Date(episode.releaseDate);
      return episodeDate >= start && episodeDate <= end;
    });
  },
};

export default appleApi;
