import React, { useState, useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Box,
  CssBaseline,
  Divider,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { Toaster } from "sonner";
import { supabase } from "./lib/supabase";

// Pages
import Home from "./pages/Home";
import Auth from "./pages/Auth";
import DiscoverPage from "./pages/DiscoverPage";
import Creator from "./pages/Creator";
import PlaylistPage from "./pages/PlaylistPage";
import CreatePlaylist from "./pages/CreatePlaylist";
import PlayerPage from "./pages/PlayerPage";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
      light: "#42a5f5",
      dark: "#1565c0",
    },
    secondary: {
      main: "#9c27b0",
      light: "#ba68c8",
      dark: "#7b1fa2",
    },
    categories: {
      morning: "#ff7043",
      lunch: "#43a047",
      evening: "#5c6bc0",
      weekend: "#8d6e63",
    },
  },
  typography: {
    fontFamily: [
      "Inter",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Arial",
      "sans-serif",
    ].join(","),
    h1: { fontWeight: 700 },
    h2: { fontWeight: 600 },
    h3: { fontWeight: 600 },
    button: { textTransform: "none" },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
  },
});

const Navigation = () => {
  const [session, setSession] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription?.unsubscribe();
  }, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuToggle = (event) => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsMobileMenuOpen(false);
  };

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      navigate("/");
      handleClose();
    } catch (error) {
      console.error("Logout error:", error.message);
    }
  };

  const hiddenPaths = ["/", "/auth"];
  if (hiddenPaths.includes(location.pathname)) return null;

  const isCreatorMode = location.pathname.includes("/creator");
  const isPlayerMode = location.pathname.includes("/player");

  return (
    <AppBar position="sticky" elevation={0}>
      <Toolbar>
        <Typography
          variant="h6"
          sx={{
            cursor: "pointer",
            fontWeight: 600,
            "&:hover": { opacity: 0.8 },
          }}
          onClick={() => navigate("/")}
        >
          Curatrs
        </Typography>

        {session && (
          <Box sx={{ ml: 2, display: "flex", gap: 2 }}>
            <Button
              color="inherit"
              onClick={() => navigate(isCreatorMode ? "/discover" : "/creator")}
            >
              Switch to {isCreatorMode ? "Discover" : "Creator"}
            </Button>
            {isPlayerMode ? (
              <Button color="inherit" onClick={() => navigate("/discover")}>
                Subscribe to Stations
              </Button>
            ) : (
              <Button color="inherit" onClick={() => navigate("/player")}>
                Go to Player
              </Button>
            )}
          </Box>
        )}

        <Box sx={{ ml: "auto", display: { xs: "none", md: "flex" }, gap: 1 }}>
          {!session ? (
            <Button
              color="inherit"
              onClick={() => navigate("/auth")}
              sx={{ fontWeight: 500 }}
            >
              Sign In
            </Button>
          ) : (
            <>
              <IconButton onClick={handleMenu} size="small">
                <Avatar
                  sx={{ width: 32, height: 32 }}
                  alt={session.user.email}
                  src={session.user.user_metadata?.avatar_url}
                />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {session.user && (
                  <MenuItem disabled sx={{ opacity: 0.7 }}>
                    {session.user.email}
                  </MenuItem>
                )}
                <Divider />
                <MenuItem onClick={handleLogout}>Sign Out</MenuItem>
              </Menu>
            </>
          )}
        </Box>

        <IconButton
          sx={{ display: { xs: "flex", md: "none" }, ml: "auto" }}
          color="inherit"
          onClick={handleMobileMenuToggle}
        >
          <MenuIcon />
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          open={isMobileMenuOpen}
          onClose={handleClose}
          sx={{ display: { xs: "block", md: "none" } }}
        >
          {session && (
            <>
              <MenuItem
                onClick={() => {
                  navigate(isCreatorMode ? "/discover" : "/creator");
                  handleClose();
                }}
              >
                Switch to {isCreatorMode ? "Discover" : "Creator"}
              </MenuItem>
              {isPlayerMode ? (
                <MenuItem
                  onClick={() => {
                    navigate("/discover");
                    handleClose();
                  }}
                >
                  Subscribe to Stations
                </MenuItem>
              ) : (
                <MenuItem
                  onClick={() => {
                    navigate("/player");
                    handleClose();
                  }}
                >
                  Go to Player
                </MenuItem>
              )}
            </>
          )}
          {session && (
            <MenuItem disabled sx={{ opacity: 0.7 }}>
              {session.user.email}
            </MenuItem>
          )}
          {!session ? (
            <MenuItem onClick={() => navigate("/auth")}>Sign In</MenuItem>
          ) : (
            <MenuItem onClick={handleLogout}>Sign Out</MenuItem>
          )}
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

const AuthGuard = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      if (!session) {
        navigate("/auth");
      }
    };
    checkAuth();
  }, [navigate]);

  return children;
};

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Box
          sx={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
        >
          <Navigation />
          <Toaster position="top-right" />

          <Box component="main" sx={{ flexGrow: 1 }}>
            <Routes>
              {/* Public Routes */}
              <Route path="/" element={<Home />} />
              <Route path="/auth" element={<Auth />} />

              {/* Protected Routes */}
              <Route
                path="/player"
                element={
                  <AuthGuard>
                    <PlayerPage />
                  </AuthGuard>
                }
              />
              <Route
                path="/discover"
                element={
                  <AuthGuard>
                    <DiscoverPage />
                  </AuthGuard>
                }
              />
              <Route
                path="/playlist/:id"
                element={
                  <AuthGuard>
                    <PlaylistPage />
                  </AuthGuard>
                }
              />
              <Route
                path="/creator"
                element={
                  <AuthGuard>
                    <Creator />
                  </AuthGuard>
                }
              />
              <Route
                path="/creator/create"
                element={
                  <AuthGuard>
                    <CreatePlaylist />
                  </AuthGuard>
                }
              />

              {/* Catch all */}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Box>
        </Box>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
