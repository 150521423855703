import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  Card,
  CardMedia,
  IconButton,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  LinearProgress,
  Skeleton,
  Alert,
  Grid,
  Divider,
  Chip,
} from "@mui/material";
import {
  PlayArrow as PlayArrowIcon,
  Pause as PauseIcon,
  PlayCircleOutline as PlayCircleOutlineIcon,
  BookmarkBorder as BookmarkBorderIcon,
  Bookmark as BookmarkIcon,
  Share as ShareIcon,
  AccessTime as AccessTimeIcon,
  ArrowBack as ArrowBackIcon,
} from "@mui/icons-material";
import usePlaylist from "../lib/Useplaylist";

const formatDuration = (seconds) => {
  if (!seconds) return "00:00";
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  if (hours > 0) {
    return `${hours}:${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  }
  return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
};

const PlaylistHeader = ({
  playlist,
  onPlay,
  onSubscribe,
  isSubscribed,
  loading,
}) => {
  if (loading) {
    return (
      <Box sx={{ mb: 4 }}>
        <Skeleton variant="rectangular" height={300} />
      </Box>
    );
  }

  const hasImage = playlist?.image_url && playlist.image_url.trim() !== "";

  return (
    <Card sx={{ display: "flex", mb: 4, bgcolor: "background.paper" }}>
      <CardMedia
        component="img"
        sx={{ width: { xs: "100%", md: 300 }, height: { xs: 200, md: 300 } }}
        image={
          hasImage
            ? playlist.image_url
            : `/api/placeholder/600/600?text=${encodeURIComponent(
                playlist?.title || ""
              )}`
        }
        alt={playlist?.title || "Playlist Cover"}
      />
      <Box sx={{ display: "flex", flexDirection: "column", p: 3, flex: 1 }}>
        <Typography variant="overline" color="text.secondary">
          {playlist?.category || "Uncategorized"}
        </Typography>
        <Typography variant="h4" component="h1" gutterBottom>
          {playlist?.title}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" gutterBottom>
          Created by {playlist?.creator?.full_name || "Unknown Creator"}
        </Typography>
        <Typography variant="body1" paragraph>
          {playlist?.description}
        </Typography>
        <Box sx={{ mt: "auto", display: "flex", gap: 2 }}>
          <Button
            variant="contained"
            startIcon={<PlayArrowIcon />}
            onClick={onPlay}
            size="large"
          >
            Play All
          </Button>
          <Button
            variant="outlined"
            startIcon={isSubscribed ? <BookmarkIcon /> : <BookmarkBorderIcon />}
            onClick={onSubscribe}
            size="large"
          >
            {isSubscribed ? "Subscribed" : "Subscribe"}
          </Button>
          <IconButton>
            <ShareIcon />
          </IconButton>
        </Box>
      </Box>
    </Card>
  );
};

const EpisodeItem = ({
  episode,
  isPlaying,
  progress = 0,
  onPlay,
  onPause,
  isCurrentEpisode,
}) => {
  const progressPercent = progress
    ? (progress / (episode.duration || 1)) * 100
    : 0;

  return (
    <ListItem
      sx={{
        bgcolor: isCurrentEpisode ? "action.selected" : "inherit",
        "&:hover": {
          bgcolor: "action.hover",
        },
      }}
    >
      <ListItemIcon>
        <IconButton onClick={isPlaying ? onPause : onPlay}>
          {isPlaying ? <PauseIcon /> : <PlayCircleOutlineIcon />}
        </IconButton>
      </ListItemIcon>
      <ListItemText
        primary={episode.title}
        secondary={episode.show_name}
        sx={{
          ".MuiListItemText-primary": {
            fontWeight: isCurrentEpisode ? 600 : 400,
          },
        }}
      />
      <ListItemSecondaryAction
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        {progress > 0 && (
          <Box sx={{ width: 100 }}>
            <LinearProgress
              variant="determinate"
              value={progressPercent}
              sx={{ height: 4, borderRadius: 2 }}
            />
          </Box>
        )}
        <Box
          sx={{ display: "flex", alignItems: "center", gap: 1, minWidth: 80 }}
        >
          <AccessTimeIcon fontSize="small" color="action" />
          <Typography variant="body2" color="text.secondary">
            {formatDuration(episode.duration || 0)}
          </Typography>
        </Box>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const PlaylistPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    playlist,
    episodes,
    currentEpisode,
    isPlaying,
    loading,
    error,
    playbackProgress,
    playEpisode,
    pauseEpisode,
    resumeEpisode,
    subscribeToPlaylist,
    unsubscribeFromPlaylist,
    calculateProgress,
  } = usePlaylist(id);

  const handleBack = () => navigate(-1);

  const handlePlayAll = () => {
    if (episodes?.length > 0) {
      playEpisode(episodes[0]);
    }
  };

  const handleEpisodePlay = (episode) => {
    if (currentEpisode?.id === episode.id) {
      resumeEpisode();
    } else {
      playEpisode(episode);
    }
  };

  const toggleSubscription = () => {
    if (playlist?.is_subscribed) {
      unsubscribeFromPlaylist();
    } else {
      subscribeToPlaylist();
    }
  };

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
        <Button startIcon={<ArrowBackIcon />} onClick={handleBack}>
          Go Back
        </Button>
      </Container>
    );
  }

  const totalDuration =
    episodes?.reduce((acc, ep) => acc + (ep.duration || 0), 0) || 0;

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Button startIcon={<ArrowBackIcon />} onClick={handleBack} sx={{ mb: 2 }}>
        Back
      </Button>

      {!loading && episodes?.length > 0 && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
            Playlist Progress
          </Typography>
          <LinearProgress
            variant="determinate"
            value={calculateProgress()}
            sx={{ height: 8, borderRadius: 4 }}
          />
        </Box>
      )}

      <PlaylistHeader
        playlist={playlist || {}}
        onPlay={handlePlayAll}
        onSubscribe={toggleSubscription}
        isSubscribed={playlist?.is_subscribed}
        loading={loading}
      />

      <Card>
        <List sx={{ bgcolor: "background.paper" }}>
          {loading ? (
            Array.from({ length: 3 }).map((_, index) => (
              <ListItem key={index}>
                <Skeleton variant="rectangular" height={60} width="100%" />
              </ListItem>
            ))
          ) : episodes?.length > 0 ? (
            episodes.map((episode) => (
              <React.Fragment key={episode.id}>
                <EpisodeItem
                  episode={episode}
                  isPlaying={isPlaying && currentEpisode?.id === episode.id}
                  progress={playbackProgress[episode.id]}
                  onPlay={() => handleEpisodePlay(episode)}
                  onPause={pauseEpisode}
                  isCurrentEpisode={currentEpisode?.id === episode.id}
                />
                <Divider />
              </React.Fragment>
            ))
          ) : (
            <ListItem>
              <ListItemText
                primary="No episodes available"
                secondary="Check back later for updates"
              />
            </ListItem>
          )}
        </List>
      </Card>

      {!loading && playlist && (
        <Grid container spacing={2} sx={{ mt: 4 }}>
          <Grid item xs={12} md={4}>
            <Card sx={{ p: 2 }}>
              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                Total Episodes
              </Typography>
              <Typography variant="h4">{episodes?.length || 0}</Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{ p: 2 }}>
              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                Total Duration
              </Typography>
              <Typography variant="h4">{formatDuration(totalDuration)}</Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{ p: 2 }}>
              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                Category
              </Typography>
              <Chip
                label={playlist.category || "Uncategorized"}
                color="primary"
                variant="outlined"
              />
            </Card>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default PlaylistPage;
