import React from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import {
  Container,
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { supabase } from "../lib/supabase";

// Separate component for the playlist card to improve readability
const PlaylistCard = ({ playlist, onEdit, onDelete, onView }) => (
  <Card>
    <CardContent>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6" gutterBottom>
          {playlist.title}
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          {playlist.description || "No description"}
        </Typography>
        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
          {playlist.category && <Chip label={playlist.category} size="small" />}
          <Chip
            label={playlist.isPublic ? "Public" : "Private"}
            size="small"
            variant="outlined"
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: 1,
        }}
      >
        <IconButton size="small" onClick={() => onEdit(playlist)}>
          <EditIcon />
        </IconButton>
        <IconButton
          size="small"
          color="error"
          onClick={() => onDelete(playlist)}
        >
          <DeleteIcon />
        </IconButton>
        <Button
          size="small"
          variant="outlined"
          onClick={() => onView(playlist)}
        >
          View
        </Button>
      </Box>
    </CardContent>
  </Card>
);

// Separate component for the delete confirmation dialog
const DeleteConfirmationDialog = ({
  playlist,
  open,
  onClose,
  onConfirm,
  isDeleting,
}) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Delete Playlist</DialogTitle>
    <DialogContent>
      Are you sure you want to delete "{playlist?.title}"? This action cannot be
      undone.
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} disabled={isDeleting}>
        Cancel
      </Button>
      <Button
        onClick={onConfirm}
        color="error"
        variant="contained"
        disabled={isDeleting}
        startIcon={isDeleting && <CircularProgress size={20} />}
      >
        {isDeleting ? "Deleting..." : "Delete"}
      </Button>
    </DialogActions>
  </Dialog>
);

const Creator = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [selectedPlaylist, setSelectedPlaylist] = React.useState(null);

  // Fetch playlists with error handling
  const {
    data: playlists = [],
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["playlists"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("playlists")
        .select("*")
        .order("created_at", { ascending: false });

      if (error) throw error;
      return data;
    },
  });

  // Delete mutation with loading state
  const deleteMutation = useMutation({
    mutationFn: async (playlistId) => {
      const { error } = await supabase
        .from("playlists")
        .delete()
        .eq("id", playlistId);

      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["playlists"] });
      toast.success("Playlist deleted successfully");
      setDeleteDialogOpen(false);
      setSelectedPlaylist(null);
    },
    onError: (error) => {
      toast.error(`Failed to delete playlist: ${error.message}`);
    },
  });

  // Handlers
  const handleCreate = () => navigate("/creator/create");
  const handleEdit = (playlist) => navigate(`/creator/edit/${playlist.id}`);
  const handleDelete = (playlist) => {
    setSelectedPlaylist(playlist);
    setDeleteDialogOpen(true);
  };
  const handleView = (playlist) => navigate(`/playlist/${playlist.id}`);
  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setSelectedPlaylist(null);
  };

  // Error state
  if (isError) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Typography color="error" align="center">
          Error loading playlists: {error.message}
        </Typography>
        <Button
          variant="outlined"
          onClick={() =>
            queryClient.invalidateQueries({ queryKey: ["playlists"] })
          }
          sx={{ display: "block", mx: "auto", mt: 2 }}
        >
          Retry
        </Button>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {/* Header */}
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 4 }}>
        <Typography variant="h4" component="h1">
          Your Playlists
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleCreate}
        >
          Create Playlist
        </Button>
      </Box>

      {/* Playlists Grid */}
      <Grid container spacing={3}>
        {isLoading ? (
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "center", py: 4 }}>
              <CircularProgress />
            </Box>
          </Grid>
        ) : playlists.length === 0 ? (
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography align="center" color="text.secondary">
                  No playlists yet. Create your first playlist!
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ) : (
          playlists.map((playlist) => (
            <Grid item xs={12} sm={6} md={4} key={playlist.id}>
              <PlaylistCard
                playlist={playlist}
                onEdit={handleEdit}
                onDelete={handleDelete}
                onView={handleView}
              />
            </Grid>
          ))
        )}
      </Grid>

      {/* Delete Confirmation Dialog */}
      <DeleteConfirmationDialog
        playlist={selectedPlaylist}
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        onConfirm={() => deleteMutation.mutate(selectedPlaylist?.id)}
        isDeleting={deleteMutation.isPending}
      />
    </Container>
  );
};

export default Creator;
