import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import { supabase } from "./supabase";
import { appleApi } from "./apple-api";

const MAX_EPISODES = 10;
const TIME_SLOTS = generateTimeSlots();

function generateTimeSlots() {
  return [
    {
      id: "00:00-02:00",
      startTime: "00:00",
      endTime: "02:00",
      label: "12:00 AM - 2:00 AM",
    },
    {
      id: "02:00-04:00",
      startTime: "02:00",
      endTime: "04:00",
      label: "2:00 AM - 4:00 AM",
    },
    {
      id: "04:00-06:00",
      startTime: "04:00",
      endTime: "06:00",
      label: "4:00 AM - 6:00 AM",
    },
    {
      id: "06:00-08:00",
      startTime: "06:00",
      endTime: "08:00",
      label: "6:00 AM - 8:00 AM",
    },
    {
      id: "08:00-10:00",
      startTime: "08:00",
      endTime: "10:00",
      label: "8:00 AM - 10:00 AM",
    },
    {
      id: "10:00-12:00",
      startTime: "10:00",
      endTime: "12:00",
      label: "10:00 AM - 12:00 PM",
    },
    {
      id: "12:00-14:00",
      startTime: "12:00",
      endTime: "14:00",
      label: "12:00 PM - 2:00 PM",
    },
    {
      id: "14:00-16:00",
      startTime: "14:00",
      endTime: "16:00",
      label: "2:00 PM - 4:00 PM",
    },
    {
      id: "16:00-18:00",
      startTime: "16:00",
      endTime: "18:00",
      label: "4:00 PM - 6:00 PM",
    },
    {
      id: "18:00-20:00",
      startTime: "18:00",
      endTime: "20:00",
      label: "6:00 PM - 8:00 PM",
    },
    {
      id: "20:00-22:00",
      startTime: "20:00",
      endTime: "22:00",
      label: "8:00 PM - 10:00 PM",
    },
    {
      id: "22:00-00:00",
      startTime: "22:00",
      endTime: "00:00",
      label: "10:00 PM - 12:00 AM",
    },
  ];
}

export function usePlaylistCreator() {
  const queryClient = useQueryClient();

  const createPlaylistMutation = useMutation({
    mutationFn: async ({ title, description, episodes, timeSlot, creator }) => {
      if (!timeSlot) {
        throw new Error("Time slot selection is required");
      }

      return supabase.from("playlists").insert({
        creator_id: creator,
        title,
        description,
        episodes: episodes.map(appleApi.formatEpisodeForStorage),
        image_url: episodes.length > 0 ? episodes[0].artworkUrl600 : "",
        time_slot: timeSlot,
        schedule_start: new Date().toISOString(),
      });
    },
    onSuccess: (playlist) => {
      queryClient.invalidateQueries(["playlists"]);
      toast.success("Playlist created successfully");
      return playlist;
    },
    onError: (error) => {
      toast.error(`Failed to create playlist: ${error.message}`);
      throw error;
    },
  });

  const fetchCreator = async () => {
    try {
      const { data, error } = await supabase
        .from("profiles")
        .select("full_name")
        .limit(1)
        .single();

      if (error) throw error;
      return data.full_name;
    } catch (error) {
      toast.error(`Failed to fetch creator: ${error.message}`);
      throw error;
    }
  };

  const searchPodcasts = async (query) => {
    if (!query) return [];

    try {
      const { data, error } = await appleApi.searchPodcasts(query);
      if (error) throw error;
      return data;
    } catch (error) {
      toast.error(`Search failed: ${error.message}`);
      throw error;
    }
  };

  const fetchPodcastEpisodes = async (podcastId) => {
    try {
      const { data, error } = await appleApi.getPodcastEpisodes(podcastId);
      if (error) throw error;
      return data;
    } catch (error) {
      toast.error(`Failed to fetch episodes: ${error.message}`);
      throw error;
    }
  };

  return {
    MAX_EPISODES,
    TIME_SLOTS,
    createPlaylistMutation,
    fetchCreator,
    searchPodcasts,
    fetchPodcastEpisodes,
  };
}
