import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  Skeleton,
  Alert,
  Button,
  TextField,
  InputAdornment,
  Grid,
  Card,
  CardContent,
  CardActions,
  Chip,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import { toast } from "sonner";
import {
  fetchPlaylists,
  fetchUserSubscriptions,
  subscribeToPlaylist,
  unsubscribeFromPlaylist,
} from "../lib/discoverLib";

const DiscoverPage = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = React.useState("");
  const [user, setUser] = React.useState(null);

  const {
    data: playlists = [],
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: ["playlists"],
    queryFn: fetchPlaylists,
  });

  const { data: subscriptions = [] } = useQuery({
    queryKey: ["subscriptions"],
    queryFn: () => fetchUserSubscriptions(user?.id),
    enabled: !!user?.id,
  });

  const isSubscribed = (playlistId) =>
    subscriptions.some((sub) => sub.playlist_id === playlistId);

  const handleSubscribe = async (playlistId) => {
    try {
      await subscribeToPlaylist(user.id, playlistId);
      toast.success("Subscribed successfully!");
      refetchSubscriptions();
    } catch (error) {
      toast.error("Failed to subscribe. Please try again.");
    }
  };

  const handleUnsubscribe = async (playlistId) => {
    try {
      await unsubscribeFromPlaylist(user.id, playlistId);
      toast.success("Unsubscribed successfully.");
      refetchSubscriptions();
    } catch (error) {
      toast.error("Failed to unsubscribe. Please try again.");
    }
  };

  const handleView = (playlist) => {
    if (!playlist?.id) return;
    navigate(`/playlist/${playlist.id}`);
  };

  const filterPlaylists = (playlists, query) => {
    if (!query?.trim()) return playlists;
    const lowercaseQuery = query.toLowerCase().trim();
    return playlists.filter(
      (playlist) =>
        playlist?.title?.toLowerCase().includes(lowercaseQuery) ||
        playlist?.description?.toLowerCase().includes(lowercaseQuery) ||
        playlist?.creator_name?.toLowerCase().includes(lowercaseQuery)
    );
  };

  const filteredPlaylists = filterPlaylists(playlists, searchQuery);

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Alert severity="error" sx={{ mb: 2 }}>
          {error.message}
        </Alert>
        <Button variant="contained" onClick={refetch}>
          Try Again
        </Button>
      </Container>
    );
  }

  return (
    <Box sx={{ pb: 8 }}>
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <TextField
          fullWidth
          placeholder="Search playlists..."
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ maxWidth: 600, mx: "auto", display: "block" }}
        />
      </Container>

      <Container maxWidth="lg">
        <Typography variant="h5" sx={{ mb: 3 }}>
          {searchQuery ? "Search Results" : "Featured Playlists"}
        </Typography>

        <Grid container spacing={3}>
          {isLoading ? (
            Array.from({ length: 6 }).map((_, idx) => (
              <Grid item xs={12} sm={6} md={4} key={`skeleton-${idx}`}>
                <Skeleton variant="rectangular" height={300} />
              </Grid>
            ))
          ) : filteredPlaylists?.length > 0 ? (
            filteredPlaylists.map((playlist) => (
              <Grid item xs={12} sm={6} md={4} key={playlist.id}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" component="div" gutterBottom>
                      {playlist.title}
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                      {playlist.creator_name}
                    </Typography>
                    <Typography variant="body2">
                      {playlist.description}
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                      <Chip
                        label={`${playlist.episode_count} episodes`}
                        size="small"
                        sx={{ mr: 1 }}
                      />
                      <Chip
                        label={`${playlist.last_7_days_plays} plays`}
                        size="small"
                      />
                    </Box>
                  </CardContent>
                  <CardActions>
                    <Button size="small" onClick={() => handleView(playlist)}>
                      View
                    </Button>
                    {user ? (
                      isSubscribed(playlist.id) ? (
                        <Button
                          size="small"
                          onClick={() => handleUnsubscribe(playlist.id)}
                        >
                          Unsubscribe
                        </Button>
                      ) : (
                        <Button
                          size="small"
                          onClick={() => handleSubscribe(playlist.id)}
                        >
                          Subscribe
                        </Button>
                      )
                    ) : null}
                  </CardActions>
                </Card>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography textAlign="center" color="text.secondary">
                {searchQuery
                  ? "No playlists found matching your search."
                  : "No featured playlists available."}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
};

export default DiscoverPage;
