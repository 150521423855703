import { useState, useEffect, useCallback, useMemo } from "react";
import { supabase } from "./supabase";

export const DEFAULT_TIME_SLOTS = [
  "6:00 AM",
  "9:00 AM",
  "12:00 PM",
  "3:00 PM",
  "6:00 PM",
  "9:00 PM",
];

// Utility function to group episodes by playlist
const groupEpisodes = (episodes) => {
  return episodes.reduce((acc, episode) => {
    if (!acc[episode.playlist_id]) {
      acc[episode.playlist_id] = [];
    }
    acc[episode.playlist_id].push(episode);
    return acc;
  }, {});
};

// Utility function to group stats by playlist
const groupStats = (stats) => {
  return stats.reduce((acc, stat) => {
    acc[stat.playlist_id] = {
      total_plays: stat.total_plays || 0,
      total_subscribers: stat.total_subscribers || 0,
      last_7_days_plays: stat.last_7_days_plays || 0,
    };
    return acc;
  }, {});
};

export const usePlayerLogic = () => {
  const [currentStation, setCurrentStation] = useState(null);
  const [currentEpisode, setCurrentEpisode] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [subscribedPlaylists, setSubscribedPlaylists] = useState([]);
  const [episodes, setEpisodes] = useState({});
  const [userId, setUserId] = useState(null);
  const [schedule, setSchedule] = useState({});
  const [audio, setAudio] = useState(null);

  // Get current user
  useEffect(() => {
    const fetchUser = async () => {
      const {
        data: { session },
        error: sessionError,
      } = await supabase.auth.getSession();
      if (sessionError) {
        setError(sessionError.message);
        return;
      }
      if (session?.user) {
        setUserId(session.user.id);
      }
    };
    fetchUser();
  }, []);

  // Load saved schedule from localStorage
  useEffect(() => {
    if (userId) {
      const savedSchedule = localStorage.getItem(`schedule_${userId}`);
      if (savedSchedule) {
        try {
          setSchedule(JSON.parse(savedSchedule));
        } catch (err) {
          console.error("Error loading schedule:", err);
        }
      }
    }
  }, [userId]);

  // Save schedule to localStorage whenever it changes
  useEffect(() => {
    if (userId && Object.keys(schedule).length > 0) {
      localStorage.setItem(`schedule_${userId}`, JSON.stringify(schedule));
    }
  }, [schedule, userId]);

  // Remove direct audio handling since we're using react-h5-audio-player

  // Fetch user's subscribed playlists
  const fetchSubscribedPlaylists = useCallback(async () => {
    if (!userId) return;

    setIsLoading(true);
    try {
      // Get subscriptions with basic playlist info
      const { data: subscriptions, error: subError } = await supabase
        .from("subscriptions")
        .select(
          `
          playlist_id,
          playlist:playlists!playlist_id (
            id,
            title,
            description,
            category,
            image_url,
            creator:profiles!creator_id (
              full_name,
              avatar_url
            )
          )
        `
        )
        .eq("user_id", userId);

      if (subError) throw subError;

      const playlistIds = subscriptions.map((sub) => sub.playlist_id);

      // Get all episodes for these playlists
      const { data: allEpisodes, error: episodeError } = await supabase
        .from("episodes")
        .select("*")
        .in("playlist_id", playlistIds);

      if (episodeError) throw episodeError;

      // Get all stats
      const { data: allStats, error: statsError } = await supabase
        .from("playlist_stats")
        .select("*")
        .in("playlist_id", playlistIds);

      if (statsError) throw statsError;

      // Group data by playlist
      const groupedEpisodes = groupEpisodes(allEpisodes || []);
      const groupedStats = groupStats(allStats || []);

      // Combine everything
      const playlists = subscriptions.map((sub) => {
        const stats = groupedStats[sub.playlist_id] || {
          total_plays: 0,
          total_subscribers: 0,
          last_7_days_plays: 0,
        };

        return {
          ...sub.playlist,
          creator_name: sub.playlist.creator?.full_name || "Unknown Creator",
          creator_avatar: sub.playlist.creator?.avatar_url,
          episode_count: groupedEpisodes[sub.playlist_id]?.length || 0,
          total_plays: stats.total_plays,
          total_subscribers: stats.total_subscribers,
          last_7_days_plays: stats.last_7_days_plays,
        };
      });

      setSubscribedPlaylists(playlists);
      setEpisodes(groupedEpisodes);
    } catch (err) {
      setError(err.message);
      console.error("Error fetching playlists:", err);
    } finally {
      setIsLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    fetchSubscribedPlaylists();
  }, [fetchSubscribedPlaylists]);

  // Get episodes for a playlist
  const getEpisodesForPlaylist = useCallback(
    async (playlistId) => {
      if (!playlistId) return null;
      if (episodes[playlistId]) return episodes[playlistId];

      try {
        const { data, error } = await supabase
          .from("episodes")
          .select(
            `
            id,
            playlist_id,
            apple_id,
            title,
            show_name,
            audio_url,
            duration,
            scheduled_for,
            description,
            image_url,
            position,
            published_at
          `
          )
          .eq("playlist_id", playlistId)
          .order("position", { ascending: true });

        if (error) throw error;

        setEpisodes((prev) => ({
          ...prev,
          [playlistId]: data,
        }));

        return data;
      } catch (err) {
        setError(err.message);
        return null;
      }
    },
    [episodes]
  );

  // Handle play action
  const handlePlay = useCallback(
    async (station) => {
      try {
        if (!station) return;

        setIsLoading(true);
        setError(null);

        // Get episodes if needed
        let stationEpisodes = episodes[station.id];
        if (!stationEpisodes) {
          stationEpisodes = await getEpisodesForPlaylist(station.id);
        }

        if (stationEpisodes?.length > 0) {
          // Find first unplayed episode or default to first episode
          const nextEpisode =
            stationEpisodes.find((ep) => !ep.completed) || stationEpisodes[0];

          const audioUrl = nextEpisode.audio_url || nextEpisode.episodeUrl;
          if (audioUrl) {
            nextEpisode.audio_url = audioUrl; // Ensure audio_url is set
            setCurrentStation(station);
            setCurrentEpisode(nextEpisode);
            setIsPlaying(true);

            // Update listening history
            if (userId) {
              await supabase.from("listening_history").upsert({
                user_id: userId,
                playlist_id: station.id,
                episode_id: nextEpisode.id,
                progress: 0,
                played_at: new Date().toISOString(),
              });
            }
          } else {
            setError("No playable episode found");
            setIsPlaying(false);
          }
        } else {
          setError("No episodes found in this playlist");
          setIsPlaying(false);
        }
      } catch (err) {
        console.error("Play error:", err);
        setError(err.message);
        setIsPlaying(false);
      } finally {
        setIsLoading(false);
      }
    },
    [userId, getEpisodesForPlaylist, episodes]
  );

  // Handle pause action
  const handlePause = useCallback(() => {
    if (audio) {
      audio.pause();
    }
    setIsPlaying(false);
  }, [audio]);

  // Subscription management
  const handleSubscribe = useCallback(
    async (playlistId) => {
      if (!userId) return;

      try {
        const { error } = await supabase.from("subscriptions").insert({
          user_id: userId,
          playlist_id: playlistId,
          created_at: new Date().toISOString(),
        });

        if (error) throw error;
        await fetchSubscribedPlaylists();
      } catch (err) {
        setError(err.message);
      }
    },
    [userId, fetchSubscribedPlaylists]
  );

  const handleUnsubscribe = useCallback(
    async (playlistId) => {
      if (!userId) return;

      try {
        if (currentStation?.id === playlistId) {
          handlePause();
          setCurrentStation(null);
          setCurrentEpisode(null);
        }

        const { error } = await supabase
          .from("subscriptions")
          .delete()
          .eq("user_id", userId)
          .eq("playlist_id", playlistId);

        if (error) throw error;
        await fetchSubscribedPlaylists();
      } catch (err) {
        setError(err.message);
      }
    },
    [userId, currentStation, handlePause, fetchSubscribedPlaylists]
  );

  // Schedule management
  const updateSchedule = useCallback((timeSlot, playlist) => {
    setSchedule((prev) => ({
      ...prev,
      [timeSlot]: playlist || null,
    }));
  }, []);

  const getScheduledPlaylist = useCallback(
    (timeSlot) => {
      return schedule[timeSlot] || null;
    },
    [schedule]
  );

  // Get current time slot
  const getCurrentTimeSlot = useCallback(() => {
    const now = new Date();
    const currentHour = now.getHours();

    return DEFAULT_TIME_SLOTS.reduce((closest, slot) => {
      const [hour, period] = slot.split(":");
      const slotHour =
        parseInt(hour) + (period === "PM" && hour !== "12" ? 12 : 0);

      if (
        !closest ||
        Math.abs(slotHour - currentHour) <
          Math.abs(parseInt(closest) - currentHour)
      ) {
        return slot;
      }
      return closest;
    });
  }, []);

  // Auto-play scheduled station
  useEffect(() => {
    const checkAndPlayScheduled = () => {
      const currentSlot = getCurrentTimeSlot();
      const scheduledPlaylist = schedule[currentSlot];

      if (
        scheduledPlaylist &&
        (!currentStation || currentStation.id !== scheduledPlaylist.id)
      ) {
        handlePlay(scheduledPlaylist);
      }
    };

    const interval = setInterval(checkAndPlayScheduled, 60000); // Check every minute
    checkAndPlayScheduled(); // Initial check

    return () => clearInterval(interval);
  }, [schedule, currentStation, getCurrentTimeSlot, handlePlay]);

  return {
    currentStation,
    currentEpisode,
    isPlaying,
    isLoading,
    error,
    subscribedPlaylists,
    episodes,
    schedule,
    userId,
    getEpisodesForPlaylist,
    handlePlay,
    handlePause,
    handleSubscribe,
    handleUnsubscribe,
    updateSchedule,
    getScheduledPlaylist,
    getCurrentTimeSlot,
    timeSlots: DEFAULT_TIME_SLOTS,
  };
};

// Utility for formatting duration
export const formatDuration = (seconds) => {
  if (!seconds) return "0:00";
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
};
