import { createClient } from "@supabase/supabase-js";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

export const authHelpers = {
  signUp: async (email, password, role) => {
    const { data, error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: {
          role: role,
        },
      },
    });
    return { data, error };
  },

  signIn: async (email, password) => {
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    return { data, error };
  },

  signInWithProvider: async (provider, role) => {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider,
      options: {
        redirectTo: `${window.location.origin}/auth/callback`,
        queryParams: {
          role: role,
        },
      },
    });
    return { data, error };
  },

  signOut: async () => {
    const { error } = await supabase.auth.signOut();
    return { error };
  },

  getSession: async () => {
    const {
      data: { session },
      error,
    } = await supabase.auth.getSession();
    return { session, error };
  },

  getUser: async () => {
    const {
      data: { user },
      error,
    } = await supabase.auth.getUser();
    return { user, error };
  },
};

export const profileHelpers = {
  getProfile: async (userId) => {
    const { data, error } = await supabase
      .from("profiles")
      .select(
        `
        id,
        full_name,
        role,
        created_at,
        avatar_url,
        updated_at
      `
      )
      .eq("id", userId)
      .single();
    return { data, error };
  },

  updateProfile: async (userId, updates) => {
    const { data, error } = await supabase
      .from("profiles")
      .update(updates)
      .eq("id", userId)
      .select();
    return { data, error };
  },
};

export const playlistHelpers = {
  createPlaylist: async (playlist) => {
    const { data, error } = await supabase.from("playlists").insert([playlist])
      .select(`
        id,
        creator_id,
        title,
        description,
        category,
        created_at,
        is_featured,
        image_url,
        updated_at
      `);
    return { data, error };
  },

  getPlaylist: async (playlistId) => {
    const { data, error } = await supabase
      .from("playlists")
      .select(
        `
        id,
        creator_id,
        title,
        description,
        category,
        created_at,
        is_featured,
        image_url,
        updated_at,
        creator:profiles!creator_id (
          id,
          full_name,
          role,
          avatar_url
        ),
        episodes:episodes (
          id,
          title,
          show_name,
          audio_url,
          duration,
          scheduled_for,
          description,
          image_url,
          position,
          published_at
        ),
        stats:playlist_stats!playlist_id (
          total_plays,
          total_subscribers,
          last_7_days_plays,
          updated_at
        )
      `
      )
      .eq("id", playlistId)
      .single();
    return { data, error };
  },

  getUserPlaylists: async (userId) => {
    const { data, error } = await supabase
      .from("playlists")
      .select(
        `
        id,
        creator_id,
        title,
        description,
        category,
        created_at,
        is_featured,
        image_url,
        updated_at,
        episodes:episodes (count),
        stats:playlist_stats!playlist_id (
          total_plays,
          total_subscribers,
          last_7_days_plays
        )
      `
      )
      .eq("creator_id", userId);
    return { data, error };
  },

  updatePlaylist: async (playlistId, updates) => {
    const { data, error } = await supabase
      .from("playlists")
      .update(updates)
      .eq("id", playlistId)
      .select();
    return { data, error };
  },

  deletePlaylist: async (playlistId) => {
    const { error } = await supabase
      .from("playlists")
      .delete()
      .eq("id", playlistId);
    return { error };
  },

  searchPlaylists: async (query) => {
    const { data, error } = await supabase
      .from("playlists")
      .select(
        `
        id,
        title,
        description,
        category,
        image_url,
        creator:profiles!creator_id (
          id,
          full_name,
          avatar_url
        ),
        stats:playlist_stats!playlist_id (
          total_plays,
          total_subscribers,
          last_7_days_plays
        )
      `
      )
      .textSearch("search_vector", query);
    return { data, error };
  },
};

export const episodeHelpers = {
  addEpisode: async (episode) => {
    const { data, error } = await supabase.from("episodes").insert([episode])
      .select(`
        id,
        playlist_id,
        apple_id,
        title,
        show_name,
        audio_url,
        duration,
        scheduled_for,
        description,
        image_url,
        position,
        published_at
      `);
    return { data, error };
  },

  getPlaylistEpisodes: async (playlistId) => {
    const { data, error } = await supabase
      .from("episodes")
      .select(
        `
        id,
        playlist_id,
        apple_id,
        title,
        show_name,
        audio_url,
        duration,
        scheduled_for,
        description,
        image_url,
        position,
        published_at
      `
      )
      .eq("playlist_id", playlistId)
      .order("position", { ascending: true });
    return { data, error };
  },

  updateEpisode: async (episodeId, updates) => {
    const { data, error } = await supabase
      .from("episodes")
      .update(updates)
      .eq("id", episodeId)
      .select();
    return { data, error };
  },

  deleteEpisode: async (episodeId) => {
    const { error } = await supabase
      .from("episodes")
      .delete()
      .eq("id", episodeId);
    return { error };
  },

  updateEpisodePosition: async (episodeId, newPosition) => {
    const { data, error } = await supabase
      .from("episodes")
      .update({ position: newPosition })
      .eq("id", episodeId)
      .select();
    return { data, error };
  },
};

export const subscriptionHelpers = {
  subscribe: async (userId, playlistId) => {
    const { data, error } = await supabase
      .from("subscriptions")
      .insert([
        {
          user_id: userId,
          playlist_id: playlistId,
          created_at: new Date().toISOString(),
        },
      ])
      .select();
    return { data, error };
  },

  unsubscribe: async (userId, playlistId) => {
    const { error } = await supabase
      .from("subscriptions")
      .delete()
      .match({ user_id: userId, playlist_id: playlistId });
    return { error };
  },

  getUserSubscriptions: async (userId) => {
    const { data, error } = await supabase
      .from("subscriptions")
      .select(
        `
        created_at,
        playlist:playlists!playlist_id (
          id,
          title,
          description,
          category,
          image_url,
          creator:profiles!creator_id (
            id,
            full_name,
            avatar_url
          ),
          episodes:episodes (count),
          stats:playlist_stats!playlist_id (
            total_plays,
            total_subscribers,
            last_7_days_plays
          )
        )
      `
      )
      .eq("user_id", userId);
    return { data, error };
  },
};

export const listeningHistoryHelpers = {
  updateProgress: async (userId, playlistId, episodeId, progress) => {
    const { data, error } = await supabase
      .from("listening_history")
      .upsert({
        user_id: userId,
        playlist_id: playlistId,
        episode_id: episodeId,
        progress,
        completed: progress >= 0.9, // Mark as completed if progress is >= 90%
        played_at: new Date().toISOString(),
      })
      .select();
    return { data, error };
  },

  getHistory: async (userId) => {
    const { data, error } = await supabase
      .from("listening_history")
      .select(
        `
        id,
        progress,
        completed,
        played_at,
        episode:episodes!episode_id (
          id,
          title,
          show_name,
          audio_url,
          duration,
          image_url
        ),
        playlist:playlists!playlist_id (
          id,
          title,
          image_url,
          creator:profiles!creator_id (
            full_name,
            avatar_url
          )
        )
      `
      )
      .eq("user_id", userId)
      .order("played_at", { ascending: false });
    return { data, error };
  },
};

export const realtimeHelpers = {
  subscribeToPlaylist: (playlistId, callback) => {
    const subscription = supabase
      .channel(`playlist:${playlistId}`)
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "episodes",
          filter: `playlist_id=eq.${playlistId}`,
        },
        (payload) => callback(payload)
      )
      .subscribe();

    return () => subscription.unsubscribe();
  },

  subscribeToUserPlaylists: (userId, callback) => {
    const subscription = supabase
      .channel(`user-playlists:${userId}`)
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "playlists",
          filter: `creator_id=eq.${userId}`,
        },
        (payload) => callback(payload)
      )
      .subscribe();

    return () => subscription.unsubscribe();
  },

  subscribeToUserSubscriptions: (userId, callback) => {
    const subscription = supabase
      .channel(`user-subscriptions:${userId}`)
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "subscriptions",
          filter: `user_id=eq.${userId}`,
        },
        (payload) => callback(payload)
      )
      .subscribe();

    return () => subscription.unsubscribe();
  },

  subscribeToPlaylistStats: (playlistId, callback) => {
    const subscription = supabase
      .channel(`playlist-stats:${playlistId}`)
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "playlist_stats",
          filter: `playlist_id=eq.${playlistId}`,
        },
        (payload) => callback(payload)
      )
      .subscribe();

    return () => subscription.unsubscribe();
  },
};

export const handleError = (error) => {
  if (error) {
    console.error("Supabase error:", error.message);
    return error.message;
  }
  return null;
};

export const TYPES = {
  ROLES: {
    LISTENER: "listener",
    CREATOR: "creator",
  },
  AUTH_PROVIDERS: {
    GOOGLE: "google",
    APPLE: "apple",
  },
};

export default supabase;
