import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { debounce } from "lodash";
import {
  TextField,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Box,
  Tabs,
  Tab,
  CircularProgress,
  Divider,
  Badge,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { usePlaylistCreator } from "../lib/CreatePlaylist";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

const CreatePlaylist = () => {
  const navigate = useNavigate();
  const {
    MAX_EPISODES,
    TIME_SLOTS,
    createPlaylistMutation,
    fetchCreator,
    searchPodcasts,
    fetchPodcastEpisodes,
  } = usePlaylistCreator();

  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedPodcasts, setSelectedPodcasts] = useState([]);
  const [selectedEpisodes, setSelectedEpisodes] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [creator, setCreator] = useState(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const initCreator = async () => {
      const creatorData = await fetchCreator();
      setCreator(creatorData);
    };
    initCreator();
  }, []);

  const handleSearch = debounce(async (query) => {
    if (!query) {
      setSearchResults([]);
      return;
    }
    const results = await searchPodcasts(query);
    setSearchResults(results);
  }, 300);

  const handleSelectPodcast = async (podcast) => {
    if (
      !selectedPodcasts.some((p) => p.collectionId === podcast.collectionId)
    ) {
      const episodes = await fetchPodcastEpisodes(podcast.collectionId);
      setSelectedPodcasts((prev) => [...prev, { ...podcast, episodes }]);
    }
    setActiveTab(1);
  };

  const handleSelectEpisode = (episode) => {
    const index = selectedEpisodes.findIndex(
      (ep) => ep.trackId === episode.trackId
    );
    if (index !== -1) {
      setSelectedEpisodes((prev) => prev.filter((_, i) => i !== index));
    } else if (selectedEpisodes.length < MAX_EPISODES) {
      setSelectedEpisodes((prev) => [...prev, episode]);
    }
  };

  const handleRemoveEpisode = (episode) => {
    setSelectedEpisodes((prev) =>
      prev.filter((ep) => ep.trackId !== episode.trackId)
    );
  };

  const onSubmit = async (data) => {
    if (selectedEpisodes.length === 0 || !selectedTimeSlot) return;

    const playlist = await createPlaylistMutation.mutateAsync({
      title: data.title,
      description: data.description,
      episodes: selectedEpisodes,
      timeSlot: selectedTimeSlot,
      creator,
    });
    navigate(`/playlists/${playlist.data.id}`);
  };

  return (
    <Box sx={{ maxWidth: 800, mx: "auto", p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Create New Playlist
      </Typography>

      <Tabs value={activeTab} onChange={(_, value) => setActiveTab(value)}>
        <Tab label="Details" />
        <Tab label="Add Episodes" />
        <Tab label="Schedule" />
        <Tab label="Review" disabled={selectedEpisodes.length === 0} />
      </Tabs>

      {activeTab === 0 && (
        <Box sx={{ mt: 3 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  name="title"
                  control={control}
                  rules={{ required: "Title is required" }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Playlist Title"
                      error={Boolean(errors.title)}
                      helperText={errors.title?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="description"
                  control={control}
                  rules={{ required: "Description is required" }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Description"
                      multiline
                      rows={4}
                      error={Boolean(errors.description)}
                      helperText={errors.description?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  onClick={() => setActiveTab(1)}
                >
                  Next: Add Episodes
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      )}

      {activeTab === 1 && (
        <Box sx={{ mt: 3 }}>
          <TextField
            fullWidth
            label="Search Podcasts"
            placeholder="Enter podcast name..."
            onChange={(e) => {
              setSearchQuery(e.target.value);
              handleSearch(e.target.value);
            }}
            sx={{ mb: 3 }}
          />

          {selectedPodcasts.map((podcast) => (
            <Box key={podcast.collectionId} sx={{ mb: 4 }}>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <Box
                  component="img"
                  src={podcast.artworkUrl100}
                  alt={podcast.collectionName}
                  sx={{ width: 40, height: 40, borderRadius: 1, mr: 2 }}
                />
                <Typography variant="h6" sx={{ flexGrow: 1 }}>
                  {podcast.collectionName}
                </Typography>
                <IconButton
                  onClick={() =>
                    setSelectedPodcasts((prev) =>
                      prev.filter(
                        (p) => p.collectionId !== podcast.collectionId
                      )
                    )
                  }
                >
                  <CloseIcon />
                </IconButton>
              </Box>

              <Divider sx={{ my: 2 }} />

              <List>
                {podcast.episodes?.map((episode) => {
                  const isSelected = selectedEpisodes.some(
                    (ep) => ep.trackId === episode.trackId
                  );
                  return (
                    <ListItem
                      key={episode.trackId}
                      onClick={() => handleSelectEpisode(episode)}
                      sx={{
                        cursor: "pointer",
                        bgcolor: isSelected ? "action.selected" : "inherit",
                        "&:hover": { bgcolor: "action.hover" },
                        borderRadius: 1,
                        mb: 1,
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          checked={isSelected}
                          onClick={(e) => e.stopPropagation()}
                          onChange={() => handleSelectEpisode(episode)}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={episode.trackName}
                        secondary={
                          <>
                            {new Date(episode.releaseDate).toLocaleDateString()}
                            {" • "}
                            {Math.round(episode.trackTimeMillis / 60000)} mins
                          </>
                        }
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          ))}

          {searchQuery && (
            <Grid container spacing={2}>
              {searchResults.map((podcast) => (
                <Grid item xs={12} sm={6} md={4} key={podcast.collectionId}>
                  <Card
                    onClick={() => handleSelectPodcast(podcast)}
                    sx={{
                      cursor: "pointer",
                      height: "100%",
                      "&:hover": { boxShadow: 6 },
                    }}
                  >
                    <CardContent>
                      <Box
                        component="img"
                        src={podcast.artworkUrl600}
                        alt={podcast.collectionName}
                        sx={{
                          width: "100%",
                          aspectRatio: "1",
                          borderRadius: 1,
                          mb: 2,
                        }}
                      />
                      <Typography variant="h6" gutterBottom>
                        {podcast.collectionName}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {podcast.artistName}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}

          <Box sx={{ position: "fixed", bottom: 32, right: 32 }}>
            <Badge badgeContent={selectedEpisodes.length} color="primary">
              <Button
                variant="contained"
                size="large"
                onClick={() => setActiveTab(2)}
                disabled={selectedEpisodes.length === 0}
              >
                Next: Schedule ({selectedEpisodes.length}/{MAX_EPISODES})
              </Button>
            </Badge>
          </Box>
        </Box>
      )}

      {activeTab === 2 && (
        <Box sx={{ mt: 3 }}>
          <Typography variant="h5" gutterBottom>
            Select Time Slot
          </Typography>

          <FormControl fullWidth sx={{ mb: 4 }}>
            <InputLabel>Time Slot</InputLabel>
            <Select
              value={selectedTimeSlot}
              onChange={(e) => setSelectedTimeSlot(e.target.value)}
              label="Time Slot"
            >
              {TIME_SLOTS.map((slot) => (
                <MenuItem key={slot.id} value={slot.id}>
                  {slot.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box sx={{ mb: 4 }}>
            <Typography variant="subtitle1" color="text.secondary" gutterBottom>
              Currently selected:
            </Typography>
            <Typography variant="h6" color="primary">
              {TIME_SLOTS.find((slot) => slot.id === selectedTimeSlot)?.label ||
                "No time slot selected"}
            </Typography>
          </Box>

          <Box sx={{ mt: 2 }}>
            <Typography variant="body2" color="text.secondary">
              Each time slot represents a 2-hour block in your daily schedule.
              Select the time that works best for your playlist to air.
            </Typography>
          </Box>

          <Box sx={{ mt: 4, display: "flex", gap: 2 }}>
            <Button
              variant="outlined"
              onClick={() => setActiveTab(1)}
              size="large"
            >
              Back to Episodes
            </Button>
            <Button
              variant="contained"
              onClick={() => setActiveTab(3)}
              size="large"
              disabled={!selectedTimeSlot}
            >
              Next: Review
            </Button>
          </Box>
        </Box>
      )}

      {activeTab === 3 && (
        <Box sx={{ mt: 3 }}>
          <Typography variant="h5" gutterBottom>
            Review Playlist
          </Typography>

          <Typography variant="h6" color="primary" gutterBottom>
            Selected Time Slot:{" "}
            {TIME_SLOTS.find((slot) => slot.id === selectedTimeSlot)?.label}
          </Typography>

          <List>
            {selectedEpisodes.map((episode, index) => (
              <ListItem
                key={episode.trackId}
                sx={{
                  bgcolor: "background.paper",
                  mb: 1,
                  borderRadius: 1,
                }}
              >
                <ListItemText
                  primary={
                    <Typography variant="subtitle1">
                      {index + 1}. {episode.trackName}
                    </Typography>
                  }
                  secondary={
                    <>
                      <Typography variant="body2" color="text.secondary">
                        From: {episode.collectionName}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {new Date(episode.releaseDate).toLocaleDateString()} •{" "}
                        {Math.round(episode.trackTimeMillis / 60000)} mins
                      </Typography>
                    </>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={() => handleRemoveEpisode(episode)}
                  >
                    <CloseIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>

          <Box sx={{ mt: 4, display: "flex", gap: 2 }}>
            <Button
              variant="outlined"
              onClick={() => setActiveTab(2)}
              size="large"
            >
              Back to Schedule
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              size="large"
              disabled={
                createPlaylistMutation.isLoading ||
                selectedEpisodes.length === 0
              }
            >
              {createPlaylistMutation.isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Create Playlist"
              )}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CreatePlaylist;
