import { useState, useEffect, useCallback } from "react";
import { supabase } from "./supabase";

const usePlaylist = (playlistId) => {
  const [user, setUser] = useState(null);
  const [playlist, setPlaylist] = useState(null);
  const [episodes, setEpisodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentEpisode, setCurrentEpisode] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [playbackProgress, setPlaybackProgress] = useState({});
  const [audio] = useState(new Audio());

  useEffect(() => {
    const getUser = async () => {
      const {
        data: { user: currentUser },
      } = await supabase.auth.getUser();
      setUser(currentUser);
    };

    getUser();

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setUser(session?.user ?? null);
    });

    return () => subscription.unsubscribe();
  }, []);

  const fetchPlaylistData = useCallback(async () => {
    try {
      setLoading(true);

      // Fetch playlist with creator info and episodes
      const { data: playlistData, error: playlistError } = await supabase
        .from("playlists")
        .select(
          `
          *,
          creator:profiles!playlists_creator_id_fkey(
            id,
            full_name,
            avatar_url
          ),
          episodes!playlist_id(*)
        `
        )
        .eq("id", playlistId)
        .single();

      if (playlistError) throw playlistError;

      // If user is logged in, check subscription status and fetch listening history
      if (user) {
        // Check subscription status
        const { data: subscriptionData } = await supabase
          .from("subscriptions")
          .select("*")
          .eq("user_id", user.id)
          .eq("playlist_id", playlistId)
          .single();

        playlistData.is_subscribed = !!subscriptionData;

        // Fetch listening history
        const { data: historyData } = await supabase
          .from("listening_history")
          .select("*")
          .eq("user_id", user.id)
          .eq("playlist_id", playlistId);

        const progressMap = {};
        historyData?.forEach((history) => {
          progressMap[history.episode_id] = history.progress;
        });
        setPlaybackProgress(progressMap);
      }

      setPlaylist(playlistData);
      setEpisodes(playlistData.episodes);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [playlistId, user]);

  const playEpisode = useCallback(
    async (episode) => {
      if (!episode?.audio_url) return;

      audio.src = episode.audio_url;
      audio.currentTime = playbackProgress[episode.id] || 0;

      try {
        await audio.play();
        setIsPlaying(true);
        setCurrentEpisode(episode);

        // Update play count
        if (user) {
          await supabase.rpc("increment_play_count", {
            p_playlist_id: playlistId,
            p_episode_id: episode.id,
          });
        }
      } catch (err) {
        setError("Failed to play audio: " + err.message);
      }
    },
    [audio, playbackProgress, playlistId, user]
  );

  const pauseEpisode = useCallback(() => {
    audio.pause();
    setIsPlaying(false);
  }, [audio]);

  const resumeEpisode = useCallback(() => {
    audio.play();
    setIsPlaying(true);
  }, [audio]);

  const updateProgress = useCallback(async () => {
    if (!currentEpisode || !user) return;

    const progress = Math.floor(audio.currentTime);
    setPlaybackProgress((prev) => ({
      ...prev,
      [currentEpisode.id]: progress,
    }));

    // Update listening history every 5 seconds
    if (progress % 5 === 0) {
      await supabase
        .from("listening_history")
        .upsert({
          user_id: user.id,
          playlist_id: playlistId,
          episode_id: currentEpisode.id,
          progress: progress,
          completed: progress >= currentEpisode.duration - 10,
          played_at: new Date().toISOString(),
        })
        .select();
    }
  }, [currentEpisode, user, playlistId, audio]);

  const subscribeToPlaylist = useCallback(async () => {
    if (!user) return;

    try {
      // Create subscription record
      const { error: subscriptionError } = await supabase
        .from("subscriptions")
        .insert([{ user_id: user.id, playlist_id: playlistId }]);

      if (subscriptionError) throw subscriptionError;

      // Update playlist stats
      await supabase.rpc("increment_subscriber_count", {
        p_playlist_id: playlistId,
      });

      setPlaylist((prev) => ({ ...prev, is_subscribed: true }));
    } catch (err) {
      setError(err.message);
    }
  }, [user, playlistId]);

  const unsubscribeFromPlaylist = useCallback(async () => {
    if (!user) return;

    try {
      // Delete subscription record
      const { error: subscriptionError } = await supabase
        .from("subscriptions")
        .delete()
        .eq("user_id", user.id)
        .eq("playlist_id", playlistId);

      if (subscriptionError) throw subscriptionError;

      // Update playlist stats
      await supabase.rpc("decrement_subscriber_count", {
        p_playlist_id: playlistId,
      });

      setPlaylist((prev) => ({ ...prev, is_subscribed: false }));
    } catch (err) {
      setError(err.message);
    }
  }, [user, playlistId]);

  const calculateProgress = useCallback(() => {
    if (!episodes?.length) return 0;

    const totalDuration = episodes.reduce(
      (acc, ep) => acc + (ep.duration || 0),
      0
    );
    const totalProgress = episodes.reduce(
      (acc, ep) => acc + (playbackProgress[ep.id] || 0),
      0
    );

    return (totalProgress / totalDuration) * 100;
  }, [episodes, playbackProgress]);

  useEffect(() => {
    fetchPlaylistData();

    // Subscribe to realtime updates
    const subscription = supabase
      .channel(`playlist:${playlistId}`)
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "episodes",
          filter: `playlist_id=eq.${playlistId}`,
        },
        (payload) => {
          if (payload.eventType === "INSERT") {
            setEpisodes((prev) =>
              [...prev, payload.new].sort((a, b) => a.position - b.position)
            );
          } else if (payload.eventType === "DELETE") {
            setEpisodes((prev) =>
              prev.filter((ep) => ep.id !== payload.old.id)
            );
          } else if (payload.eventType === "UPDATE") {
            setEpisodes((prev) =>
              prev.map((ep) => (ep.id === payload.new.id ? payload.new : ep))
            );
          }
        }
      )
      .subscribe();

    return () => {
      subscription.unsubscribe();
      audio.pause();
      audio.src = "";
    };
  }, [playlistId, audio, fetchPlaylistData]);

  useEffect(() => {
    const handleTimeUpdate = () => updateProgress();
    const handleEnded = () => {
      setIsPlaying(false);
      const currentIndex = episodes.findIndex(
        (ep) => ep.id === currentEpisode?.id
      );
      if (currentIndex < episodes.length - 1) {
        playEpisode(episodes[currentIndex + 1]);
      }
    };

    audio.addEventListener("timeupdate", handleTimeUpdate);
    audio.addEventListener("ended", handleEnded);

    return () => {
      audio.removeEventListener("timeupdate", handleTimeUpdate);
      audio.removeEventListener("ended", handleEnded);
    };
  }, [audio, episodes, currentEpisode, playEpisode, updateProgress]);

  return {
    user,
    playlist,
    episodes,
    currentEpisode,
    isPlaying,
    loading,
    error,
    playbackProgress,
    playEpisode,
    pauseEpisode,
    resumeEpisode,
    subscribeToPlaylist,
    unsubscribeFromPlaylist,
    calculateProgress,
  };
};

export default usePlaylist;
